<template>
    <SideNavbar @isShowNavbar="receiveDataFromChild" />
    <div class="animation-duration-item-navbar"
        :class="{ 'padding-body-show-navbar': isShowNavbar, 'padding-body-hide-navbar': !isShowNavbar }">

        <div class=" mt-1 ps-2">
            <div class="bg-green-custom ps-2 py-2 text-light font-custom-3 fw-bold">
                Pemetaan Kelas
            </div>
            <div class="d-flex flex-column gap-2 mt-2">
                <div class="row me-0">
                    <div class="col-2 text-center">
                        <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                            v-model="prodiFilter" @change="stambukFilter = ''; kelasFilter = ''; index();;">
                            <option value="" selected disabled>- Pilih Prodi</option>
                            <option v-for="list in prodiList" :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div>
                    <div class="col-2 text-center">
                        <select class="form-select" aria-label="Default select example" name="kelas" id="prodi"
                            v-model="stambukFilter" @change="index()" :disabled="prodiFilter == ''">
                            <option value="" selected disabled>- Pilih Stambuk</option>
                            <option v-for="list in stambukList" :key="list.nama" :value="list.id">
                                {{ list.nama.substring(0, 4) }}
                            </option>
                        </select>
                    </div>
                    <div class="col-2 text-center">
                        <select class="form-select" aria-label="Default select example" name="kelas" id="kelas"
                            v-model="kelasFilter" @change="index()" :disabled="stambukFilter == ''">
                            <option value="" selected disabled>- Pilih Kelas</option>
                            <option v-for="list in kelasList" :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div>
                    <div class="col-2 text-center">
                        <input type="text" v-model="query" @change="index()" class="form-control"
                            placeholder="Cari berdasarkan nama, nim">
                    </div>
                    <div class=" col-4 d-flex align-items-center justify-content-end">
                        <button v-if="create_akses == true"
                            class="btn bg-green-custom fs-8 py-1 text-light font-custom-4 me-2"
                            style="border-radius: 5px;" data-bs-toggle="modal" data-bs-target="#modal_export"
                            @click="showModal('modal_export');">
                            <i class='bx bx-export'></i>
                            Export
                        </button>
                        <button v-if="create_akses == true"
                            class="btn bg-green-custom fs-8 py-1 text-light font-custom-4 me-2"
                            style="border-radius: 5px;" data-bs-toggle="modal" data-bs-target="#modal_import"
                            @click="showModal('modal_import');">
                            <i class='bx bx-export'></i>
                            Import
                        </button>
                    </div>

                </div>
                <div class="row">
                    <div class="col-6">
                        <div class="border mt-1 rounded-3 me-2 font-custom-1" style="overflow-y:auto; z-index: 0;"
                            :style="dynamicStyle">
                            <table class="table mb-0 bg-transparent table-borderless " style="">
                                <thead style="
                                            position: sticky !important;
                                            top: 0px !important;
                                            background-color: white;
                                            z-index: 1;
                                        ">
                                    <tr>
                                        <th scope="col" class="bg-transparent fs-7">No</th>
                                        <th scope="col" class="font-custom-1 fs-7">NIM</th>
                                        <th scope="col" class="font-custom-1 fs-7">Nama </th>
                                        <th scope="col" class="font-custom-1 fs-7">Jenis Kelamin</th>
                                        <th scope="col" class="font-custom-1 fs-7">Waktu kuliah</th>
                                        <th scope="col" class="bg-transparent  fs-7">Aksi</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(record, index) in mahasiswaRecords" :key="record.id" :ref="record.id"
                                        class="border-bottom ">
                                        <th scope="row" class="bg-transparent ">{{ index + 1 }}</th>
                                        <td>{{ record.nim_mahasiswa }}</td>
                                        <td>{{ record.nama_lengkap }}</td>
                                        <td>{{ record.jenis_kelamin }}</td>
                                        <td>{{ record.waktu_kuliah }}</td>
                                        <td class="bg-transparent">
                                            <div class="d-flex gap-2">
                                                <button v-if="update_akses == true" class="btn btn-primary btn-sm fs-8"
                                                    v-on:click="insert(record.id)"><i class='bx bx-plus'></i></button>
                                            </div>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="border mt-1 rounded-3 me-2 font-custom-1" style="overflow-y:auto; z-index: 0;"
                            :style="dynamicStyle2">

                            <table class="table mb-0 bg-transparent table-borderless ">
                                <thead style="
                                            position: sticky !important;
                                            top: 0px !important;
                                            background-color: white;
                                            z-index: 1;
                                        ">
                                    <tr>
                                        <th scope="col" class="bg-transparent fs-7">No</th>
                                        <th scope="col" class="font-custom-1 fs-7">NIM</th>
                                        <th scope="col" class="font-custom-1 fs-7">Nama</th>
                                        <th scope="col" class="font-custom-1 fs-7">Jenis kelamin </th>
                                        <th scope="col" class="font-custom-1 fs-7">Waktu kuliah</th>

                                        <th scope="col" class="bg-transparent  fs-7">Aksi</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(record, index) in records" :key="record.id" :ref="record.id"
                                        class="border-bottom ">
                                        <th scope="row" class="bg-transparent ">{{ index + 1 }}</th>
                                        <td>{{ record.nim_mahasiswa }}</td>
                                        <td>{{ record.mahasiswa }}</td>
                                        <td>{{ record.jenis_kelamin }}</td>
                                        <td>{{ record.waktu_kuliah }}</td>
                                        <td class="bg-transparent">
                                            <div class="d-flex gap-2">
                                                <!-- <button class="btn btn-warning btn-sm fs-8" v-on:click="editRecord(record)"
                                                    data-bs-toggle="modal" data-bs-target="#modal_edit"><i
                                                        class='bx bxs-message-square-edit fs-8'></i></button> -->
                                                <button v-if="update_akses == true" class="btn btn-danger btn-sm fs-8"
                                                    data-bs-toggle="modal" data-bs-target="#modal_delete"
                                                    v-on:click="deleteRecord(record.id)"><i
                                                        class='bx bxs-trash-alt fs-8'></i></button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
            </div>

        </div>

    </div>
    <!-- <form @submit.prevent="insert()">
        <div class="modal fade" id="modal_tambah" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle"
            aria-hidden="true" ref="modal_tambah">

            <div class="modal-dialog modal-fullscreen modal-dialog-centered modal-dialog-scrollable" role="document">
                <div class="modal-content ">

                    <div class="modal-header bg-green-custom text-light">
                        <h5 class="modal-title" id="exampleModalScrollableTitle">Tambah Data</h5>
                        <button hidden type="button" data-bs-dismiss="modal" aria-label="Close"
                            ref="close_modal_tambah"></button>
                        <button type="button" class="btn bg-transparent text-light border-0">
                            <img src="@/assets/icon/close.png" alt="" width="30"
                                @click="hideModal('modal_tambah', 'close_modal_tambah')">
                        </button>
                    </div>

                    <div class="modal-body">
                        <div class="mb-3">
                            <label for="fakultas" class="form-label">Prodi</label>
                            <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                                v-model="newRecord.prodi" @change="index(newRecord.prodi)" required>
                                <option value="" selected disabled>- Pilih Prodi</option>
                                <option v-for="list in prodiList" :key="list.id" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="fakultas" class="form-label">Kelas</label>
                            <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                                v-model="newRecord.kelas" required>
                                <option value="" selected disabled>- Pilih Waktu Kuliah</option>
                                <option v-for="list in kelasList" :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="fakultas" class="form-label">Mahasiswa</label>
                            <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                                v-model="newRecord.mahasiswa" required>
                                <option value="" selected disabled>- Pilih Waktu Kuliah</option>
                                <option v-for="list in kelasList" :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>


                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary"
                            @click="hideModal('modal_tambah', 'close_modal_tambah')">Tutup</button>
                        <button type="submit" class="btn bg-green-custom text-light">
                            Simpan</button>
                    </div>

                </div>

            </div>

        </div>
    </form> -->

    <div class="modal fade" id="modal_export" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle"
        aria-hidden="true" ref="modal_export">
        <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">

            <div class="modal-content ">

                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Edit Data</h5>
                    <button hidden type="button" data-bs-dismiss="modal" aria-label="Close"
                        ref="close_modal_export"></button>
                    <button type="button" class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30"
                            @click="hideModal('modal_export', 'close_modal_export')">
                    </button>
                </div>

                <div class="modal-body">
                    <div class="mb-2">
                        <label for="prodi" class="form-label mt-3">Prodi</label>
                        <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                            v-model="eksportRecord.prodi" @change="index(eksportRecord.prodi);">
                            <option value="" selected disabled>- Pilih Prodi</option>
                            <option v-for="list in prodiList" :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div>
                    <div class="mb-2">
                        <label for="stambuk" class="form-label mt-3">Stambuk</label>
                        <select class="form-select" aria-label="Default select example" name="kelas" id="prodi"
                            v-model="eksportRecord.tahun_ajaran" @change="index(eksportRecord.prodi)"
                            :disabled="eksportRecord.prodi == ''">
                            <option value="" selected disabled>- Pilih Stambuk</option>
                            <option v-for="list in stambukList" :key="list.nama" :value="list.id">
                                {{ list.nama.substring(0, 4) }}
                            </option>
                        </select>
                    </div>


                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary"
                        @click="hideModal('modal_export', 'close_modal_export')">Tutup</button>
                    <button type="button"
                        @click="EksportExcelData(eksportRecord, 'pemetaan_kelas_export', 'Pemetaan Kelas', config)"
                        class="btn bg-green-custom text-light">
                        Eksport </button>
                </div>
            </div>
        </div>
    </div>


    <div class="modal fade" id="modal_delete" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle"
        aria-hidden="true" ref="modal_delete">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content ">
                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Hapus Data</h5>
                    <button hidden type="button" data-bs-dismiss="modal" aria-label="Close"
                        ref="close_modal_delete"></button>
                    <button type="button " class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30"
                            @click="hideModal('modal_delete', 'close_modal_delete')">
                    </button>
                </div>
                <div class="modal-body">
                    <div>
                        Apakah Anda yakin ingin menghapus data ini? data tidak dapat dikembalikan
                    </div>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary"
                        @click="hideModal('modal_delete', 'close_modal_delete')">Tidak, tetap simpan disini</button>
                    <button type="button" class="btn bg-danger text-light" @click="deleteData">
                        Ya,Hapus</button>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="modal_import" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle"
        aria-hidden="true" ref="modal_import">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content ">
                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Import Data</h5>
                    <button hidden type="button" data-bs-dismiss="modal" aria-label="Close"
                        ref="close_modal_import"></button>
                    <button type="button " class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30"
                            @click="hideModal('modal_import', 'close_modal_import')">
                    </button>
                </div>
                <form @submit.prevent="importData">
                    <div class="modal-body">
                        <div class="mb-3">
                            *Catatan : Harap Mengikuti Format yang ada. <br />
                            <label for="file_input" class="form-label mt-3">Pilih File</label>
                            <input type="file" name="file_input" class="form-control" id="file_input" />
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary"
                            @click="hideModal('modal_delete', 'close_modal_delete')">
                            Batal
                        </button>
                        <button type="submit" class="btn btn-primary" id="summary">
                            Import
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <button class="btn btn-success d-none" id="btn_laporan_import" data-bs-toggle="modal"
        data-bs-target="#laporan_import" ref="btn_laporan_import" hidden>
        <i class="bx bxs-file-import"></i> Laporan Import Pemetaan Kelas
    </button>
    <div>
        <div class="modal fade" id="laporan_import" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false"
            aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">
                            Status Import
                            <span class="text-capitalize"> Pemetaan Kelas</span>
                        </h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="mb-3">
                            <table class="table fs-6">
                                <thead style="
                                position: sticky !important;
                                top: -20px !important;
                                background-color: white;
                                ">
                                    <tr>
                                        <th scope="col" style="width: 5%">No</th>
                                        <th scope="col">status</th>
                                        <th scope="col">Data</th>
                                    </tr>
                                </thead>
                                <tbody id="data_laporan">
                                    <tr v-for="(laporanRecords, index) in laporanRecords" :key="laporanRecords.id">
                                        <td>{{ index + 1 }}</td>
                                        <td>
                                            <i v-if="laporanRecords.status === 'SUKSES'"
                                                class="bx bxs-check-square text-success"></i>
                                            <i v-if="laporanRecords.status === 'GAGAL'"
                                                class="bx bxs-x-square text-danger"></i>
                                        </td>
                                        <td>{{ laporanRecords.text }}</td>
                                    </tr>


                                </tbody>
                            </table>
                            <!-- <div class="modal-footer ">
                                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" id="btn_tutup_laporan_kehadiran_2" onclick="refresh_page()">Tutup</button>
                                </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.padding-body-show-navbar {
    padding-left: 190px !important;
}

.padding-body-hide-navbar {
    padding-left: 82px !important;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}


@keyframes bounceIn {
    0% {
        transform: scale(0.5);
        opacity: 0.1;
    }

    50% {
        transform: scale(1.05);
        opacity: 0.5;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.delete-animation {
    animation: fadeLeft 1s ease;
}

@keyframes fadeLeft {
    from {
        opacity: 1;
        transform: translateX(0);
    }

    to {
        opacity: 0;
        transform: translateX(-20px);
    }
}


@keyframes bounceOut {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    50% {
        transform: scale(1);
        opacity: 0.4;
    }

    100% {
        transform: scale(1);
        opacity: 0;
    }
}

.animation-hide-modal {
    animation: bounceOut 0.3s ease-in;
}

.animation-show-modal {
    animation: bounceIn 0.3s ease-out;
}


.table tbody tr:last-child {
    border: 0px white !important;
}
</style>

<script>
import { AlertPopup, base_url, CloseLoading, menu_akses_cek, ImportExcel, ShowLoading, ErrorConnectionTimeOut, AlertBottom, CharAndNumberOnly, EksportExcelData, } from "@/assets/javascript/function";
import SideNavbar from "@/components/SideNavbar.vue";
import Cookies from "js-cookie";
import axios from "axios";

export default {
    data() {
        return {
            //data
            url: 'pemetaan_kelas',
            api: "",


            //pages
            limit: "1000",
            previousLastId: "",
            NextLastId: "",
            previousLastIdOld: "",
            NextLastIdOld: "",
            arrayLastIdOld: [],
            arrayPreviousIdOld: [],
            numberPage: 1,
            totalPages: 0,

            prodiList: [],
            waktuKuliahList: [],
            dosenWaliList: [],
            stambukList: [],
            kelasList: [],

            //filter
            prodiFilter: "",
            stambukFilter: "",
            kelasFilter: "",
            query: "",

            //eksport 
            eksportRecord: {
                prodi: "",
                tahun_ajaran: "",
            },

            //akses
            create_akses: false,
            update_akses: false,
            delete_akses: false,


            //tampilan
            isShowNavbar: true,
            temporaryId: "",
            records: [],
            mahasiswaRecords: [],
            newRecord: { nama: "", prodi: "", waktu_kuliah: "", dosen_wali: "", stambuk: "", keterangan: "", kelas: "" },
            currentRecord: {},
            config: "",

            laporanRecords: "",

        }
    },
    components: {
        SideNavbar,
    },
    computed: {
        dynamicStyle() {
            if (this.mahasiswaRecords.length > 10) {
                return {
                    'height': '75vh!important'
                };
            } else {
                return {}; // Empty object if the condition isn't met
            }
        },
        dynamicStyle2() {
            if (this.records.length > 10) {
                return {
                    'height': '75vh!important'
                };
            } else {
                return {}; // Empty object if the condition isn't met
            }
        }
    },


    methods: {
        CharAndNumberOnly,
        EksportExcelData,
        index(prodi) {
            this.prodiList = [];
            this.records = [];
            this.mahasiswaRecords = [];
            ShowLoading();
            let formData = new FormData();

            formData.append("previous_id", '');
            formData.append("last_id", '');
            formData.append("limit", this.limit);
            formData.append("query", this.query);
            formData.append("kelas", this.kelasFilter);
            formData.append("stambuk", this.stambukFilter);
            if (prodi == undefined) {
                formData.append("prodi", this.prodiFilter);
            } else {
                formData.append("prodi", prodi);
            }

            // for (let [key, value] of formData.entries()) {
            //     console.log(`${key}: ${value}`);
            // }


            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + '_index';
            } else {
                this.api = base_url + this.url + '_index';
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    console.log(response.data);
                    if (response.data.status == true) {
                        if (localStorage.getItem('tipe_user') == 'dosen') {
                            for (let i = 0; i < response.data.prodi_akses.length; i++) {
                                const index = response.data.prodi.findIndex((record) => record.id === response.data.prodi_akses[i].id);
                                this.prodiList.push(response.data.prodi[index]);
                            }
                        } else {
                            this.prodiList = response.data.prodi;
                        }

                        this.waktuKuliahList = response.data.waktu_kuliah;
                        this.dosenWaliList = response.data.dosen_wali;
                        this.stambukList = response.data.stambuk;
                        this.kelasList = response.data.kelas;
                        this.mahasiswaRecords = response.data.mahasiswa;
                        // if (response.data.mahasiswa[0] != undefined) {
                        //     this.mahasiswaRecords.unshift(response.data.mahasiswa[0])
                        //     this.mahasiswaRecords.unshift(response.data.mahasiswa[0])
                        //     this.mahasiswaRecords.unshift(response.data.mahasiswa[0])
                        //     this.mahasiswaRecords.unshift(response.data.mahasiswa[0])
                        //     this.mahasiswaRecords.unshift(response.data.mahasiswa[0])
                        //     this.mahasiswaRecords.unshift(response.data.mahasiswa[0])
                        //     this.mahasiswaRecords.unshift(response.data.mahasiswa[0])
                        //     this.mahasiswaRecords.unshift(response.data.mahasiswa[0])
                        //     this.mahasiswaRecords.unshift(response.data.mahasiswa[0])
                        //     this.mahasiswaRecords.unshift(response.data.mahasiswa[0])
                        //     this.mahasiswaRecords.unshift(response.data.mahasiswa[0])
                        //     this.mahasiswaRecords.unshift(response.data.mahasiswa[0])
                        //     this.mahasiswaRecords.unshift(response.data.mahasiswa[0])
                        //     this.mahasiswaRecords.unshift(response.data.mahasiswa[0])
                        // }


                        if (response.data.data != undefined) {
                            if (prodi == undefined) {
                                if (this.stambukFilter != '' && this.kelasFilter != '') {
                                    this.records = response.data.data[1];
                                }





                                this.totalPages = response.data.data[0][0].number_page;
                                this.previousLastId = response.data.data[1].id;
                                this.previousLastIdOld = response.data.data[1].id;
                                let last_index = Object.keys(response.data.data[0]).pop();

                                this.NextLastId = response.data.data[1][last_index].id;
                                this.NextLastIdOld = response.data.data[1][last_index].id;
                                this.arrayLastIdOld.push(response.data.data[1][last_index].id);
                            }




                            // this.arrayPreviousIdOld.push(response.data.data[1][0].id);

                        }
                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },

        index_next_page(param1, param2) {

            ShowLoading();
            let formData = new FormData();
            formData.append("previous_id", "");
            formData.append("last_id", param2);
            formData.append("limit", this.limit);

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + '_index';
            } else {
                this.api = base_url + this.url + '_index';
            }
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        this.totalPages = response.data.data[0][0].number_page;
                        this.arrayLastIdOld.push(param2);
                        this.arrayPreviousIdOld.push(param1);

                        this.records = response.data.data[1];
                        let last_index = Object.keys(response.data.data[0]).pop();

                        this.NextLastId = response.data.data[1][last_index].id; // id 33
                        this.previousLastId = response.data.data[1][0].id; // 7
                        this.numberPage = this.numberPage + 1;
                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        index_before_page() {

            let last_index = this.arrayLastIdOld.length;
            last_index = last_index - 1;
            ShowLoading();
            let formData = new FormData();
            formData.append("url", "ra_admin_verifikasi_page");
            formData.append("previous_id", this.arrayPreviousIdOld[last_index]);
            formData.append("last_id", "");
            formData.append("limit", this.limit);

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + '_index';
            } else {
                this.api = base_url + this.url + '_index';
            }
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        this.totalPages = response.data.data[0][0].number_page;

                        this.records = response.data.data[1];
                        let last_index = Object.keys(response.data.data[1]).pop();
                        this.NextLastId = response.data.data[1][last_index].id;
                        this.previousLastId = response.data.data[1][0].id;

                        if (this.arrayLastIdOld.length != 1) {
                            this.arrayLastIdOld.pop();
                            this.arrayPreviousIdOld.pop();
                            this.numberPage = this.numberPage - 1;
                        }

                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },

        insert(id) {
            ShowLoading();
            const formData = new FormData();

            // Object.entries(this.newRecord).forEach(([key, value]) => {
            //     formData.append(key, value);
            // });
            formData.append('mahasiswa', id);
            formData.append('kelas', this.kelasFilter);
            if (this.kelasFilter == '') {
                AlertPopup("error", "", "Pilih kelas terlebih dahulu", 1500, false);
                return true;
            }

            // for (let [key, value] of formData.entries()) {
            //     console.log(`${key}: ${value}`);
            // }
            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + "_insert";
            } else {
                this.api = base_url + this.url + "_insert";
            }


            // Add a new record to the API
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {


                        this.records.unshift(response.data.data);



                        // Clear the form inputs
                        this.newRecord = {

                        };
                        CloseLoading();
                        AlertBottom(response.data.message);
                        const index = this.mahasiswaRecords.findIndex((record) => record.id === id);
                        this.mahasiswaRecords.splice(index, 1);

                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                    // console.log(error);
                });
        },

        editRecord(record) {
            // Set the current record to edit mode
            this.currentRecord = Object.assign({}, record);
            this.showModal('modal_edit');

            this.setRecordwithId('prodi', this.prodiList, 'nama', 'id');
            this.setRecordwithId('dosen_wali', this.dosenWaliList, 'nama', 'id');
            this.setRecordwithId('stambuk', this.stambukList, 'nama', 'id');
            this.setRecordwithId('waktu_kuliah', this.waktuKuliahList, 'nama', 'id');

        },
        setRecordwithId(field, arraylist, fieldNameArray, valueWantAdd) {
            if (this.currentRecord[field] != null) {
                const index = arraylist.findIndex((record) => record[fieldNameArray] == this.currentRecord[field]);
                this.currentRecord[field] = arraylist[index][valueWantAdd];
            } else {
                this.currentRecord[field] = "";
            }
        },
        update() {
            ShowLoading();
            const formData = new FormData();

            Object.entries(this.currentRecord).forEach(([key, value]) => {
                formData.append(key, value);
            });
            // for (let [key, value] of formData.entries()) {
            //     console.log(`${key}: ${value}`);
            // }

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + "_update";
            } else {
                this.api = base_url + this.url + "_update";
            }

            // Add a new record to the API
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    console.log(response);
                    if (response.data.status == true) {
                        const index = this.records.findIndex(
                            (record) => record.id == response.data.data.id
                        );
                        this.currentRecord = {}
                        // this.$set(this.records, index, response.data.data);
                        this.records.splice(index, 1, response.data.data);


                        CloseLoading();
                        AlertBottom(response.data.message);
                        this.hideModal('modal_edit', 'close_modal_edit');
                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        deleteData() {

            ShowLoading();
            const formData = new FormData();
            formData.append("id", this.temporaryId);
            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + "_delete";
            } else {
                this.api = base_url + this.url + "_delete";
            }
            // Delete a record from the API
            axios
                .post(this.api, formData, this.config)

                .then((response) => {
                    if (response.data.status == true) {
                        // Remove the record from the records list
                        const index = this.records.findIndex((record) => record.id === this.temporaryId);
                        this.records.splice(index, 1);
                        this.mahasiswaRecords.unshift(response.data.data);
                        CloseLoading();
                        AlertBottom(response.data.message);
                        this.hideModal('modal_delete', 'close_modal_delete');

                    } else {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },


        //antimation
        receiveDataFromChild(data) {
            this.isShowNavbar = data;
        },
        showModal(modal_name) {
            this.$refs[modal_name].classList.remove('animation-hide-modal')
            this.$refs[modal_name].classList.add('animation-show-modal')

        },
        hideModal(modal_name, close_button_modal) {
            this.$refs[modal_name].classList.remove('animation-show-modal')
            this.$refs[modal_name].classList.add('animation-hide-modal')
            setTimeout(() => {
                this.$refs[close_button_modal].click();
            }, 150)


        },
        deleteRecord(id) {

            this.temporaryId = id;
            console.log(this.temporaryId)
            this.showModal('modal_delete');
        },
        deleteRecordApi() {
            const index = this.records.findIndex((record) => record.id === this.temporaryId);
            // this.$refs[this.temporaryId].classList.add('bg-danger');
            setTimeout(() => {
                this.$refs['close_modal_delete'].click();
                this.records.splice(index, 1);
            }, 1000)
        },
        importData() {
            ShowLoading();
            ImportExcel(event.target.file_input.files[0], 'pemetaan_kelas', this.config).then(
                (response) => {
                    if (response == undefined) {
                        //
                        console.log(response);
                    } else {
                        let data = [];

                        for (let i = 0; i < response.message.length; i++) {
                            data.push(response.message[i]);
                        }

                        for (let i = 0; i < data.length; i++) {
                            if (data[i].colors == "danger") {
                                data.unshift(data[i]);
                                data.splice(i, i);
                            }
                        }
                        this.laporanRecords = data;

                        this.$refs.btn_laporan_import.click();
                        this.index();
                    }
                }
            );
        },
    },

    // mounted() {

    // },
    created() {
        this.token = Cookies.get("token");
        this.config = {
            headers: { Authorization: `Bearer ` + this.token },
            timeout: 0,
        }
        this.index();
        //akses
        this.create_akses = menu_akses_cek(this.url, 'create');
        this.update_akses = menu_akses_cek(this.url, 'update');
        this.delete_akses = menu_akses_cek(this.url, 'delete');

    }



}
</script>