<template>
    <SideNavbar @isShowNavbar="receiveDataFromChild" />
    <div class="animation-duration-item-navbar"
        :class="{ 'padding-body-show-navbar': isShowNavbar, 'padding-body-hide-navbar': !isShowNavbar }">

        <div class=" mt-1 ps-2">
            <div class="bg-green-custom ps-2 py-2 text-light fs-5 fw-bold">
                NILAI YANG BELUM DIFINALISASI
            </div>
            <div class="d-flex flex-column gap-2 mt-2">
                <div class="row me-0 py-2 bg-white">
                    <div class="col-2 text-center">
                        <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                            v-model="tahunAjaranFilter" @change="index()" required>
                            <option value="" selected disabled>- Pilih Tahun Ajaran</option>
                            <option v-for="list in tahunAjaranList" :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div>
                    <div class="col-2 text-center">
                        <select class="form-select" aria-label="Default select example" name="semester" id="semester"
                            v-model="semesterFilter" @change="index()" :disabled="tahunAjaranFilter == ''" required>
                            <option value="" selected>- Pilih Semester</option>
                            <option v-for="list in semesterList" :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div>
                    <div class="col-3">
                        <input type="text" v-model="query" @change="index()" class="form-control" placeholder="Cari">
                    </div>
                </div>
                <div class="text-danger">
                    *Jika data kosong atau tidak ada disini maka artinya nilai sudah terfinalisasi dengan baik
                </div>

                <div class="border mt-1 rounded-3 me-2 font-custom-1">
                    <table class="table mb-0 bg-transparent  " style="">
                        <thead>
                            <tr>
                                <th scope="col" class="fw-bolder bg-transparent ">No</th>
                                <th scope="col" class="fw-bolder font-custom-1 ">Prodi</th>
                                <th scope="col" class="fw-bolder font-custom-1 ">Kelas</th>
                                <th scope="col" class="fw-bolder font-custom-1 ">Kode MK</th>
                                <th scope="col" class="fw-bolder font-custom-1 ">Mata <br> Kuliah</th>



                                <th scope="col" class="fw-bolder font-custom-1 ">Dosen <br>Pengajar</th>

                                <th scope="col" class="fw-bolder font-custom-1 "> UTS <br> DOSEN </th>
                                <th scope="col" class="fw-bolder font-custom-1 "> UTS <br>KAPRODI</th>

                                <th scope="col" class="fw-bolder font-custom-1 "> UAS <br> DOSEN </th>
                                <th scope="col" class="fw-bolder font-custom-1 "> UAS <br> KAPRODI</th>
                                <!-- <th scope="col" class="font-custom-1 fs-7"> UTS AKADEMIK</th> -->
                                <!-- <th scope="col" class="font-custom-1 fs-7">Dosen Utama </th>
                                <th scope="col" class="font-custom-1 fs-7">Dosen Utama 2</th>
                                <th scope="col" class="font-custom-1 fs-7">Dosen Pendamping</th> -->
                                <!-- <th scope="col" class="font-custom-1 fs-7"> UAS & DLL AKADEMIK</th> -->
                                <!-- <th scope="col" class="font-custom-1 fs-7"> SEMS PENDEK DOSEN</th>
                                <th scope="col" class="font-custom-1 fs-7"> SEMS PENDEK KAPRODI</th>
                                <th scope="col" class="font-custom-1 fs-7"> SEMS PENDEK AKADEMIK</th> -->
                                <!-- <th scope="col" class="bg-transparent  fs-7">Aksi</th> -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(record, index) in records" :key="record.id" :ref="record.id"
                                class="border-bottom ">
                                <th scope="row" class="bg-transparent ">{{ index + 1 }}</th>
                                <td>{{ record.prodi }}</td>
                                <td>{{ record.kelas }}</td>
                                <td>{{ record.kode_mata_kuliah }}</td>
                                <td>{{ record.nama_mata_kuliah }}</td>

                                <!-- <td>{{ record.dosen_utama }}</td>
                                <td>{{ record.dosen_utama_2 }}</td>
                                <td>{{ record.dosen_pendamping }}</td> -->
                                <td>{{ record.dosen_pengajar }}</td>
                                <!-- <td>{{ record.status_nilai_upload_uts_dosen }}</td>
                                <td>{{ record.status_nilai_upload_uts_kaprodi }}</td> -->
                                <td class="text-center">
                                    <span
                                        v-if="record.status_nilai_upload_uts_dosen && record.status_nilai_upload_uts_dosen === 'Sudah Difinalisasi'">
                                        <i class='bx bxs-check-circle text-success'></i>
                                    </span>

                                    <span v-else>
                                        <i class='bx bxs-x-circle text-danger'></i>
                                    </span>

                                </td>
                                <td class="text-center">
                                    <span
                                        v-if="record.status_nilai_upload_uts_kaprodi && record.status_nilai_upload_uts_kaprodi === 'Sudah Difinalisasi'">
                                        <i class='bx bxs-check-circle text-success'></i>
                                    </span>

                                    <span v-else>
                                        <i class='bx bxs-x-circle text-danger'></i>
                                    </span>

                                </td>

                                <td class="text-center">
                                    <span
                                        v-if="record.status_nilai_upload_uas_dosen && record.status_nilai_upload_uas_dosen === 'Sudah Difinalisasi'">
                                        <i class='bx bxs-check-circle text-success'></i>
                                    </span>

                                    <span v-else>
                                        <i class='bx bxs-x-circle text-danger'></i>
                                    </span>

                                </td>

                                <td class="text-center">
                                    <span
                                        v-if="record.status_nilai_upload_uas_kaprodi && record.status_nilai_upload_uas_kaprodi === 'Sudah Difinalisasi'">
                                        <i class='bx bxs-check-circle text-success'></i>
                                    </span>

                                    <span v-else>
                                        <i class='bx bxs-x-circle text-danger'></i>
                                    </span>

                                </td>

                                <!-- <td>{{ record.status_nilai_upload_uts_akademik }}</td> -->
                                <!-- <td>{{ record.status_nilai_upload_uas_dll_dosen }}</td>
                                <td>{{ record.status_nilai_upload_uas_dll_kaprodi }}</td> -->
                                <!-- <td>{{ record.status_nilai_upload_uas_dll_akademik }}</td> -->
                                <!-- <td>{{ record.status_nilai_upload_semester_pendek_dosen }}</td>
                                <td>{{ record.status_nilai_upload_semester_pendek_kaprodi }}</td>
                                <td>{{ record.status_nilai_upload_semester_pendek_akademik }}</td> -->
                                <!-- <td>{{ record.status_finalisasi_uas_dosen }}</td>
                                <td>{{ record.status_finalisasi_uas_kaprodi }}</td>
                                <td>{{ record.status_finalisasi_uas_akademik }}</td> -->
                                <!-- <td class="bg-transparent">
                                    <div class="d-flex gap-2">
                                        <button v-if="update_akses == true" class="btn btn-warning btn-sm fs-8"
                                            v-on:click="editRecord(record)" data-bs-toggle="modal"
                                            data-bs-target="#modal_edit"><i
                                                class='bx bxs-message-square-edit fs-8'></i></button>
                                        <button v-if="delete_akses == true" class="btn btn-danger btn-sm fs-8"
                                            data-bs-toggle="modal" data-bs-target="#modal_delete"
                                            v-on:click="deleteRecord(record.id)"><i
                                                class='bx bxs-trash-alt fs-8'></i></button>
                                    </div>

                                </td> -->

                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>


    </div>

    <div class="modal fade" id="modal_delete" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle"
        aria-hidden="true" ref="modal_delete">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content ">
                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Hapus Data</h5>
                    <button hidden type="button" data-bs-dismiss="modal" aria-label="Close"
                        ref="close_modal_delete"></button>
                    <button type="button " class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30"
                            @click="hideModal('modal_delete', 'close_modal_delete')">
                    </button>
                </div>
                <div class="modal-body">
                    <div>
                        Apakah Anda yakin ingin menghapus data ini? data tidak dapat dikembalikan
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary"
                        @click="hideModal('modal_delete', 'close_modal_delete')">Tidak, tetap simpan disini</button>
                    <button type="button" class="btn bg-danger text-light" @click="deleteData">
                        Ya,Hapus</button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.padding-body-show-navbar {
    padding-left: 190px !important;
}

.padding-body-hide-navbar {
    padding-left: 82px !important;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}


@keyframes bounceIn {
    0% {
        transform: scale(0.5);
        opacity: 0.1;
    }

    50% {
        transform: scale(1.05);
        opacity: 0.5;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.delete-animation {
    animation: fadeLeft 1s ease;
}

@keyframes fadeLeft {
    from {
        opacity: 1;
        transform: translateX(0);
    }

    to {
        opacity: 0;
        transform: translateX(-20px);
    }
}


@keyframes bounceOut {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    50% {
        transform: scale(1);
        opacity: 0.4;
    }

    100% {
        transform: scale(1);
        opacity: 0;
    }
}

.animation-hide-modal {
    animation: bounceOut 0.3s ease-in;
}

.animation-show-modal {
    animation: bounceIn 0.3s ease-out;
}


.table tbody tr:last-child {
    border: 0px white !important;
}
</style>

<script>
import {
    AlertPopup, base_url, CloseLoading, ShowLoading, ErrorConnectionTimeOut, rupiah,

} from "@/assets/javascript/function";
import SideNavbar from "@/components/SideNavbar.vue";
import Cookies from "js-cookie";
import axios from "axios";

export default {
    data() {
        return {

            //data
            tahunAjaranList: [],
            semesterList: [],
            tahunAjaranFilter: '',
            semesterFilter: '',
            records: [],
            query: '',
            //ui
            isShowNavbar: true,

        }
    },
    components: {
        SideNavbar,
    },

    methods: {
        rupiah,
        //data

        index() {
            ShowLoading();
            let formData = new FormData();

            formData.append('tahun_ajaran', this.tahunAjaranFilter);
            formData.append('semester', this.semesterFilter);
            formData.append('query', this.query);
            formData.append("limit", this.limit);

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'status_finalisasi';
            } else {
                this.api = base_url + 'status_finalisasi';
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    console.log(response);
                    if (response.data.status == true) {
                        this.tahunAjaranList = response.data.tahun_ajaran;
                        this.semesterList = response.data.semester;

                        if (response.data.data != undefined) {
                            this.records = response.data.data;
                        }
                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    AlertPopup("error", "", "mohon maaf terjadi kesalahan silahkan coba lagi", 1500, false);
                    ErrorConnectionTimeOut(error);
                });

        },
        //antimation
        receiveDataFromChild(data) {
            this.isShowNavbar = data;
        },
        showModal(modal_name) {
            this.$refs[modal_name].classList.remove('animation-hide-modal')
            this.$refs[modal_name].classList.add('animation-show-modal')

        },
        hideModal(modal_name, close_button_modal) {
            this.$refs[modal_name].classList.remove('animation-show-modal')
            this.$refs[modal_name].classList.add('animation-hide-modal')
            setTimeout(() => {
                this.$refs[close_button_modal].click();
            }, 150)
        },
    },
    created() {
        this.token = Cookies.get("token");
        this.config = {
            headers: { Authorization: `Bearer ` + this.token },
            timeout: 0,
        }
        this.index();
    }
}
</script>