<template>
    <SideNavbar @isShowNavbar="receiveDataFromChild" />
    <div class="animation-duration-item-navbar"
        :class="{ 'padding-body-show-navbar': isShowNavbar, 'padding-body-hide-navbar': !isShowNavbar }">

        <div class=" mt-1 ps-2">

            <div class="bg-green-custom ps-2 py-2 text-light font-custom-3 fw-bold sticky-top ">
                Notifikasi
            </div>

            <div class="mt-1">
                <select class="form-select" aria-label="Default select example" name="notifikasi" id="notifikasi"
                    v-model="NotifikasiFilter" @change="index" required>
                    <option value="">Semua</option>
                    <option value="berita_acara_dosen">BA Dosen</option>
                    <option value="ujian_susulan">Ujian Susulan</option>
                    <option value="score_mahasiswa">Score Mahasiswa</option>
                    <option value="pembayaran">Pembayaran</option>
                </select>
            </div>

            <div class=" position-relative mt-2 rounded-3 px-2 py-2 border border-2 bg-border-green-custom "
                v-for="(record) in records" :key="record.id" :ref="record.id">
                <span class="title fw-bold">{{ record.judul }}</span>
                <p class="">
                    {{ record.konten }}
                </p>
                <span class="position-absolute top-0 end-0 me-2">{{ record.tanggal }}</span>

            </div>

        </div>

    </div>
</template>

<style scoped>
.truncate-lines {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* Number of lines to show */
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.padding-body-show-navbar {
    padding-left: 190px !important;
}

.padding-body-hide-navbar {
    padding-left: 82px !important;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}


@keyframes bounceIn {
    0% {
        transform: scale(0.5);
        opacity: 0.1;
    }

    50% {
        transform: scale(1.05);
        opacity: 0.5;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.delete-animation {
    animation: fadeLeft 1s ease;
}

@keyframes fadeLeft {
    from {
        opacity: 1;
        transform: translateX(0);
    }

    to {
        opacity: 0;
        transform: translateX(-20px);
    }
}


@keyframes bounceOut {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    50% {
        transform: scale(1);
        opacity: 0.4;
    }

    100% {
        transform: scale(1);
        opacity: 0;
    }
}

.animation-hide-modal {
    animation: bounceOut 0.3s ease-in;
}

.animation-show-modal {
    animation: bounceIn 0.3s ease-out;
}


.table tbody tr:last-child {
    border: 0px white !important;
}
</style>

<script>
import { AlertPopup, base_url, CloseLoading, ShowLoading, removeHtmlTags, ErrorConnectionTimeOut, CharAndNumberOnly, DateFormat } from "@/assets/javascript/function";
import SideNavbar from "@/components/SideNavbar.vue";
import Cookies from "js-cookie";
import axios from "axios";

export default {
    data() {
        return {
            //data
            url: 'notifikasi',
            url2: 'berita',
            api: "",

            NotifikasiFilter: "",

            //pages
            limit: "50",
            previousLastId: "",
            NextLastId: "",
            previousLastIdOld: "",
            NextLastIdOld: "",
            arrayLastIdOld: [],
            arrayPreviousIdOld: [],
            numberPage: 1,
            totalPages: 0,
            offset: 0,

            ditujukanKepadaList: [
                {
                    nama: "dosen"
                },
                {
                    nama: "pegawai"
                },
                {
                    nama: "mahasiswa"
                }
            ],
            ditujukanKepadaFilter: "pegawai",

            //tampilan
            isShowNavbar: true,
            temporaryId: "",
            records: [],
            newRecord: { judul: "", isi: "", ditujukan_kepada: "", gambar: "", },
            currentRecord: {},




        }
    },
    components: {
        SideNavbar,
    },

    methods: {
        CharAndNumberOnly,
        removeHtmlTags,
        DateFormat,

        index() {

            this.paginglist = [];
            ShowLoading();
            this.records = [];


            let formData = new FormData();

            formData.append("ditujukan", this.ditujukanKepadaFilter);
            formData.append("limit", this.limit);
            formData.append("offset", this.offset);
            formData.append("filter", this.NotifikasiFilter);
            formData.append("query", this.query);


            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + '_index';
            } else {
                this.api = base_url + this.url + '_index';
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        if (response.data.data != undefined) {
                            this.records = response.data.data[1];
                            this.totalPages = response.data.data[0][0].number_page;
                        }
                        // console.log(response.data.data);
                        localStorage.setItem('lastReadNotif', response.data.data[1][0].judul + response.data.data[1][0].tanggal);

                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },

        editRecord(record) {
            // Set the current record to edit mode
            this.currentRecord = Object.assign({}, record);
            this.showModal('modal_edit');

            // this.setRecordwithId('prodi', this.prodiList, 'nama', 'id');
            // this.setRecordwithId('dosen_wali', this.dosenWaliList, 'nama', 'id');
            // this.setRecordwithId('stambuk', this.stambukList, 'nama', 'id');
            // this.setRecordwithId('waktu_kuliah', this.waktuKuliahList, 'nama', 'id');

        },
        setRecordwithId(field, arraylist, fieldNameArray, valueWantAdd) {
            if (this.currentRecord[field] != null) {
                const index = arraylist.findIndex((record) => record[fieldNameArray] == this.currentRecord[field]);
                this.currentRecord[field] = arraylist[index][valueWantAdd];
            } else {
                this.currentRecord[field] = "";
            }
        },

        //antimation
        receiveDataFromChild(data) {
            this.isShowNavbar = data;
        },


        showModal(modal_name) {
            this.$refs[modal_name].classList.remove('animation-hide-modal')
            this.$refs[modal_name].classList.add('animation-show-modal')

        },
        hideModal(modal_name, close_button_modal) {
            this.$refs[modal_name].classList.remove('animation-show-modal')
            this.$refs[modal_name].classList.add('animation-hide-modal')
            setTimeout(() => {
                this.$refs[close_button_modal].click();
            }, 150)


        },
        deleteRecord(id) {

            this.temporaryId = id;
            id;
            this.showModal('modal_delete');
        },
        deleteRecordApi() {
            const index = this.records.findIndex((record) => record.id === this.temporaryId);
            // this.$refs[this.temporaryId].classList.add('bg-danger');
            setTimeout(() => {
                this.$refs['close_modal_delete'].click();
                this.records.splice(index, 1);
            }, 1000)
        }
    },

    // mounted() {

    // },
    created() {
        this.token = Cookies.get("token");
        this.config = {
            headers: { Authorization: `Bearer ` + this.token },
            timeout: 0,
        }



        this.index();
        localStorage.setItem('notifSum', 0)

    }



}
</script>